import React, { useState } from 'react'
import { categories } from '../../libs/menuCat'
const MenuCategories = ({onCategorySelect}) => {
    const [selectedCategory, setSelectedCategory] = useState(1);

    const handleCategory = (id) => {
        setSelectedCategory(id);
        onCategorySelect(id); // Call the callback function with the selected category ID
    };
  return (
    <header class="header" data-header>
      
    <div class="container">
    
      <nav class="navbar active" data-navbar>
        <ul class="navbar-list categories-list">
            {
                categories.map((category) =>(
                    <li class="navbar-item" key={category.id}>
                        <a onClick={()=>handleCategory(category.id)} class={`navbar-link hover-underline cursor-pointer ${category.id === selectedCategory ? 'active':''}`}>
                            <div class="separator"></div>
                            <div className='titleGroup'>
                              <span class="span" style={{'display':'flex','justifyItems':'center'}}>{category.title}{category.forAdults? <span className='adultsTag'>+18</span>:''}</span>
                              
                            </div>
                        </a>
                    </li>
                ))
            }
        </ul>
      </nav>
    </div>
  </header>
  )
}

export default MenuCategories