import React from 'react'
import Logo from '../Logo/Logo'

const NavBar = () => {
  return (
    <>
        <div class="topbar">
    <div class="container">

      <address class="topbar-item">
        <div class="icon">
          <ion-icon name="location-outline" aria-hidden="true"></ion-icon>
        </div>

        <span class="span" style={{'textAlign':'center'}}>
          Via Luigi Pasteur 2 Anguillara Sabazia
        </span>
      </address>

      {/* <div class="separator"></div> */}

      

      <a href="tel:+390688911750" class="topbar-item link">
        <div class="icon">
          <ion-icon name="call-outline" aria-hidden="true"></ion-icon>
        </div>

        <span class="span" style={{'textAlign':'center'}}>+39 068 8911750</span>
      </a>
      <a href="tel:+393291399718" class="topbar-item link">
        <div class="icon">
          <ion-icon name="call-outline" aria-hidden="true"></ion-icon>
        </div>

        <span class="span" style={{'textAlign':'center'}}>+39 329 1399718</span>
      </a>

      {/* <div class="separator"></div> */}

      <a href="mailto:info@carthage2023.com.mx" class="topbar-item link">
        <div class="icon">
          <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>
        </div>

        <span class="span" style={{'textAlign':'center'}}>info@carthage2023.com.mx</span>
      </a>

    </div>
  </div>
  <header class="header" data-header>
    <div class="container">

      <a href="#" class="logo">
        <Logo />
      </a>

      <nav class="navbar" data-navbar>

        <button class="close-btn" aria-label="close menu" data-nav-toggler>
          <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
        </button>

        <a href="#" class="logo">
          <Logo />
        </a>

        <ul class="navbar-list">

          {/* <li class="navbar-item">
            <a href="#home" class="navbar-link hover-underline active">
              <div class="separator"></div>

              <span class="span">Home</span>
            </a>
          </li> */}

        </ul>

        <div class="text-center">
          <p class="headline-1 navbar-title">Visit Us</p>

          <address class="body-4">
            Restaurant St, Delicious City, <br />
            London 9578, UK
          </address>

          <p class="body-4 navbar-text">Open: 9.30 am - 2.30pm</p>

          <a href="mailto:booking@grilli.com" class="body-4 sidebar-link">booking@grilli.com</a>

          <div class="separator"></div>

          <p class="contact-label">Booking Request</p>

          <a href="tel:+88123123456" class="body-1 contact-number hover-underline">
            +88-123-123456
          </a>
        </div>

      </nav>

      {/* <a href="#" class="btn btn-secondary">
        <span class="text text-1">Find A Table</span>

        <span class="text text-2" aria-hidden="true">Find A Table</span>
      </a> */}

      <button class="nav-open-btn" aria-label="open menu" data-nav-toggler>
        <span class="line line-1"></span>
        <span class="line line-2"></span>
        <span class="line line-3"></span>
      </button>

      <div class="overlay" data-nav-toggler data-overlay></div>

    </div>
  </header>
    </>
  )
}

export default NavBar