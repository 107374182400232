import React from 'react'
import LogoImage from '../../assets/images/logo.png';
const Logo = () => {
  return (
    <>
      <img src={LogoImage} alt="Logo" width={160}/>
    </>
  )
}

export default Logo