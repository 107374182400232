export const products=[
    {
        id:1,
        title:'MARGHERITA',
        desc:"pomodoro e mozzarella",
        price:"6",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:2,
        title:'ORTOLANA',
        desc:"verdure miste e mozzarella (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:3,
        title:'CAPRICCIOSA',
        desc:"pomodoro, mozzarella, funghi, prosciutto crudo, uovo e olive (1,7,3,6)",
        price:"9",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:4,
        title:'NAPOLI',
        desc:"pomodoro, mozzarella e alici (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:5,
        title:'MARINARA',
        desc:"pomodoro e origano (1,6)",
        price:"5",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:6,
        title:'BOSCAIOLA',
        desc:"mozzarella, funghi e salsiccia (1,6,7)",
        price:"9",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:7,
        title:'QUATTRO FORMAGGI',
        desc:"mozzarella, brie, provola e parmigiano (1,6,7)",
        price:"8",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:8,
        title:'WURSTEL',
        desc:"pomodoro, mozzarella e wurstel (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:9,
        title:'FUNGHI',
        desc:"pomodoro, mozzarella e funghi (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:10,
        title:'BUFALA E PACHINO',
        desc:"pomodoro, mozzarella e bufala (1,6,7)",
        price:"8",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:11,
        title:'BRESAOLA E RUCOLA',
        desc:"mozzarella, bresaola, rucola e grana (1,6,7)",
        price:"8",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:12,
        title:'TONNO E CIPOLLA',
        desc:"pomodoro, mozzarella, tonno e cipolla (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:13,
        title:'FIORI DI ZUCCA E ALICI',
        desc:"mozzarella, fiori di zucca e alici (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:14,
        title:'PIZZA CROSTINO',
        desc:"mozzarella e prosciutto cotto (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:15,
        title:'DIAVOLA',
        desc:"pomodoro, mozzarella e salame piccante (1,6,7)",
        price:"7",
        category:1,
        thumbnail:"",
        images:"",
    },
    {
        id:16,
        title:'CARPE DIEM',
        desc:"avocado, scaglie di tartufo, rucola e pachino (1,6,7)",
        price:"12",
        category:2,
        thumbnail:"",
        images:"",
    },
    {
        id:17,
        title:'DALILA',
        desc:"fiori di zucca, gamberetti, mozzarella e pachino (1,6,7)",
        price:"12",
        category:2,
        thumbnail:"",
        images:"",
    },
    {
        id:18,
        title:'AMATRICIANA',
        desc:"pomodoro, guanciale, pecorino e pepe (1,6,7)",
        price:"10",
        category:2,
        thumbnail:"",
        images:"",
    },
    {
        id:19,
        title:'PEPPE',
        desc:"stracchino, prosciutto cotto e cicoria ripassata (1,6,7)",
        price:"9",
        category:2,
        thumbnail:"",
        images:"",
    },
    {
        id:20,
        title:'SALMONE',
        desc:"philadelphia, salmone affumicato e rucola",
        price:"12",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:21,
        title:'ROSINA',
        desc:"salsa rosa, gamberetti e insalata",
        price:"12",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:22,
        title:'BOLOGNA',
        desc:"mortadella e formaggio al pistacchio",
        price:"12",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:23,
        title:'PARMA',
        desc:"prosciutto crudo e burrata",
        price:"12",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:24,
        title:'CAPRESE',
        desc:"pomodoro fresco, mozzarella e basilico",
        price:"12",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:25,
        title:'TROPEA',
        desc:"tonno, cipolla e pomodoro fresco",
        price:"10",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:26,
        title:'PUPA',
        desc:"arrosto di tacchino, mozzarella e cicoria ripassata",
        price:"10",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:27,
        title:'CARTAGINE',
        desc:"peperoncino, cipolla caramellata, uova strapazzate, tonno, pomodoro fresco, formaggio, olive, prezzemolo",
        price:"12",
        category:3,
        thumbnail:"",
        images:"",
    },
    {
        id:28,
        title:'2 gusti a scelta',
        desc:"",
        price:"15",
        category:4,
        thumbnail:"",
        images:"",
    },
    {
        id:29,
        title:'3 gusti a scelta',
        desc:"",
        price:"18",
        category:4,
        thumbnail:"",
        images:"",
    },
    {
        id:30,
        title:'Focaccia semplice (1,6)',
        desc:"",
        price:"3,5",
        category:5,
        thumbnail:"",
        images:"",
    },
    {
        id:31,
        title:'Focaccia con prosciutto crudo (1,6)',
        desc:"",
        price:"5",
        category:5,
        thumbnail:"",
        images:"",
    },
    {
        id:32,
        title:'Patate fritte',
        desc:"",
        price:"3",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:33,
        title:'Verdure miste fritte',
        desc:"",
        price:"5",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:34,
        title:'Olive ascolane (5pz)',
        desc:"",
        price:"3",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:35,
        title:'Mozzarelline (5 pz)',
        desc:"",
        price:"3",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:36,
        title:'Crocchetta di patate',
        desc:"",
        price:"1,5",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:37,
        title:'Fiore di zucca',
        desc:"",
        price:"2",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:38,
        title:'Filetto di baccala’',
        desc:"",
        price:"2,5",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:39,
        title:'Suppli mozzarella e pomodoro',
        desc:"",
        price:"1,5",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:40,
        title:'Suppli cacio e pepe',
        desc:"",
        price:"2",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:41,
        title:'Suppli carbonara',
        desc:"",
        price:"2",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:42,
        title:'Suppli amatriciana',
        desc:"",
        price:"2",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:43,
        title:'Suppli ragu’',
        desc:"",
        price:"2",
        category:6,
        thumbnail:"",
        images:"",
    },
    {
        id:44,
        title:'Panna cotta (7)',
        desc:"",
        price:"5",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:45,
        title:'Panna cotta al pistacchio (7)',
        desc:"",
        price:"6",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:46,
        title:'Tartufo al pistacchio',
        desc:"*alcuni dei nostri prodotti possono essere surgelati",
        price:"6",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:47,
        title:'Tartufo bianco',
        desc:"*alcuni dei nostri prodotti possono essere surgelati",
        price:"5",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:48,
        title:'Tartufo al cioccolato',
        desc:"*alcuni dei nostri prodotti possono essere surgelati",
        price:"5",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:49,
        title:'Tiramisu’ (1,3,7,5,8,6,11)',
        desc:"",
        price:"5",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:50,
        title:'Pizza con nutella (1,6)',
        desc:"",
        price:"5",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:51,
        title:'Zoccolette con nutella (1,6,7)',
        desc:"",
        price:"6",
        category:7,
        thumbnail:"",
        images:"",
    },
    {
        id:52,
        title:'Acqua naturale 1,5 l',
        desc:"",
        price:"1,5",
        category:8,
        thumbnail:"",
        images:"",
    },
    {
        id:53,
        title:'Acqua naturale 0,5 l',
        desc:"",
        price:"1",
        category:8,
        thumbnail:"",
        images:"",
    },
    {
        id:54,
        title:'Acqua frizzante 1,5 l',
        desc:"",
        price:"1,5",
        category:8,
        thumbnail:"",
        images:"",
    },
    {
        id:55,
        title:'Acqua frizzante 0,5 l',
        desc:"",
        price:"1",
        category:8,
        thumbnail:"",
        images:"",
    },
    {
        id:56,
        title:'Succo di frutta',
        desc:"",
        price:"1,5",
        category:8,
        thumbnail:"",
        images:"",
    },
    {
        id:57,
        title:'Coca cola',
        desc:"",
        price:"2",
        category:9,
        thumbnail:"",
        images:"",
    },
    {
        id:58,
        title:'Coca cola zero',
        desc:"",
        price:"2",
        category:9,
        thumbnail:"",
        images:"",
    },
    {
        id:59,
        title:'Fanta',
        desc:"",
        price:"2",
        category:9,
        thumbnail:"",
        images:"",
    },
    {
        id:60,
        title:'Sprite',
        desc:"",
        price:"2",
        category:9,
        thumbnail:"",
        images:"",
    },
    {
        id:61,
        title:'Tè al limone',
        desc:"",
        price:"2",
        category:9,
        thumbnail:"",
        images:"",
    },
    {
        id:62,
        title:'Tè alla pesca',
        desc:"",
        price:"2",
        category:9,
        thumbnail:"",
        images:"",
    },
    {
        id:63,
        title:'Coca cola',
        desc:"",
        price:"3",
        category:10,
        thumbnail:"",
        images:"",
    },
    {
        id:64,
        title:'Coca cola zero',
        desc:"",
        price:"3",
        category:10,
        thumbnail:"",
        images:"",
    },
    {
        id:65,
        title:'Fanta',
        desc:"",
        price:"3",
        category:10,
        thumbnail:"",
        images:"",
    },
    {
        id:66,
        title:'Sprite',
        desc:"",
        price:"3",
        category:10,
        thumbnail:"",
        images:"",
    },
    {
        id:67,
        title:'Tè al limone',
        desc:"",
        price:"3",
        category:10,
        thumbnail:"",
        images:"",
    },
    {
        id:68,
        title:'Tè alla pesca',
        desc:"",
        price:"3",
        category:10,
        thumbnail:"",
        images:"",
    },
    {
        id:69,
        title:'Becks',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:70,
        title:'Heineken',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:71,
        title:'Nastro azzurro',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:72,
        title:'Peroni',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:73,
        title:'Ceres',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:74,
        title:'Corona',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:75,
        title:'Moretti',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:76,
        title:'Ichnusa',
        desc:"",
        price:"3",
        category:11,
        thumbnail:"",
        images:"",
    },
    {
        id:77,
        title:'Becks',
        desc:"",
        price:"3,5",
        category:12,
        thumbnail:"",
        images:"",
    },
    {
        id:78,
        title:'Heineken',
        desc:"",
        price:"3,5",
        category:12,
        thumbnail:"",
        images:"",
    },
    {
        id:79,
        title:'Nastro azzurro',
        desc:"",
        price:"3,5",
        category:12,
        thumbnail:"",
        images:"",
    },
    {
        id:80,
        title:'Peroni',
        desc:"",
        price:"3,5",
        category:12,
        thumbnail:"",
        images:"",
    },
    
]