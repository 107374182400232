import React, {useState} from 'react'
import MenuItem from '../../comp/MenuItem/MenuItem'
import Shape1 from '../../assets/images/shape-5.png';
import Shape2 from '../../assets/images/shape-6.png';
import MenuCategories from '../../comp/MenuCategories/MenuCategories';
import BasicLayout from '../../layouts/BasicLayout';
import {products} from '../../libs/products';

import { categories } from '../../libs/menuCat';
const Menu = () => {
    const [category, setCategory] = useState(1)
    
    const handleCategorySelect = (id) => {
        setCategory(id);       
    };
    console.log("Selected category ID:", category);

    const categoryItem = categories.find(categoryItem => categoryItem.id === category);
    const filteredProducts = products.filter(product => product.category === category);
  return (
    <BasicLayout>
        <section class="section menu" aria-label="menu-label" id="menu" style={{"minHeight":"100vh"}}>
        <MenuCategories onCategorySelect={handleCategorySelect} />
            <div class="container">
            <p class="section-subtitle text-center label-2"></p>
            <h2 class="headline-1 section-title text-center">{categoryItem.title}</h2>
            <ul class="grid-list">
                {}
                {filteredProducts.map(product => (
                            <li key={product.id}>
                                <MenuItem product={product} />
                            </li>
                ))}
            </ul>
            <img src={Shape1} width="921" height="1036" loading="lazy" alt="shape"
                class="shape shape-2 move-anim"/>
            <img src={Shape2} width="343" height="345" loading="lazy" alt="shape"
                class="shape shape-3 move-anim"/>
                <span className='headline-1 text-center' style={{'textAlign':'center','width':'100%', 'color':'var(--gold-crayola','margin':'12px 0px'}}>CONSEGNA A DOMICILIO <br /> A PARTIRE DA 1,5€</span>
            </div>
        </section>
    </BasicLayout>
  )
}

export default Menu