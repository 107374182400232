import './assets/css/style.css'
import router from './Routes';
import { RouterProvider } from "sanity/router";
import Menu from './pages/Menu/Menu';

function App() {
  return (
    
      <Menu />

  );
}

export default App;
