export const categories=[
    {
        id:1,
        forAdults:false,
        title:"Pinsa Romana"
    },
    {
        id:2,
        forAdults:false,
        title:"Pinsa Special",
    },
    {
        id:3,
        forAdults:false,
        title:"Pinsa Baciata",
    },
    {
        id:4,
        forAdults:false,
        title:"Pinsa alla pala",
    },
    {
        id:5,
        forAdults:false,
        title:"Focaccia",
    },
    {
        id:6,
        forAdults:false,
        title:"Fritti",
    },
    
    {
        id:7,
        forAdults:false,
        title:"Dessert",
    },
    {
        id:8,
        forAdults:false,
        title:"Bevande",
    },
    
    {
        id:9,
        forAdults:false,
        title:"Bibite in lattina 33 cl",
    },
    {
        id:10,
        forAdults:false,
        title:"Bibite in lattina 1,5 L",
    },
    {
        id:11,
        forAdults:true,
        title:"Birre 33 CL",
    },
    {
        id:12,
        forAdults:true,
        title:"Birre 66 CL",
    }

]