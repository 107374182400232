import { createBrowserRouter  } from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout";

import Menu from "./pages/Menu/Menu";

const router = createBrowserRouter(
    [
        {
            path:"/",
            element: <BasicLayout />,
            
            children:[
                {
                    path:"/",
                    element:<Menu />,
                    index:true,
                }
            ]
        },

       
        
    ]
)

export default router;