import React from 'react'
import MenuItemImage from '../../assets/images/menu-3.png';
import {products} from '../../libs/products'
const MenuItem = ({product}) => {
    
  return (
    <div class="menu-card hover:card">

            <figure className="card-banner img-holder" style={{ "--width": "100", "--height": "100" }}>
                {product.thumbnail !== "" && <img src={MenuItemImage} width="100" height="100" loading="lazy" alt={product.title} className="img-cover" />}
            </figure>

                <div>

                  <div class="title-wrapper">
                    <h3 class="title-3">
                      <a href="#" class="card-title">{product.title}</a>
                    </h3>

                    <span class="span title-2">{product.price} €</span>
                  </div>

                  <p class="card-text label-1">
                    {product.desc}
                  </p>

                </div>

              </div>
  )
}

export default MenuItem